<template>
  <div class="landing-page">
    <h1 class="title-main">Augmented Intelligence</h1>
    <h2 class="title-sub">Enhancing <span class="highlight-sub">Human Capabilities</span></h2>
    <p class="description">
      neocore is creating the world’s first empowering companion for users,
      <span class="highlight">enhancing decision-making, learning, and entertainment</span>
      through real-time cognitive insights with brain-computer interface technology
    </p>
  </div>
  <div class="info-text">
    <p>join our mailing list below, to get updates and <br><span class="one-more-thing">ONE MORE THING</span></p>
  </div>
  <div class="form-container">
    <iframe src="https://tally.so/embed/meAMK0?hideTitle=1&transparentBackground=1&dynamicHeight=1"></iframe>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  mounted() {
    console.log('LandingPage mounted');
  }
}
</script>

<style scoped>
.landing-page {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: 'Helvetica', sans-serif;
  max-width: 800px; /* Set max width for the text */
  margin-left: auto;
  margin-right: auto;
}

.title-main {
  font-size: 36px;
  font-weight: bold;
  color: black;
}

.title-sub {
  font-size: 24px;
  color: white;
}

.highlight-sub {
  background-color: black;
  color: white;
  padding: 0 5px;
}

.description {
  font-size: 24px;
  font-weight: 300;
}

.highlight {
  font-weight: bold;
}

.info-text {
  text-align: center;
  margin-top: 20px;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  font-weight: 100;
}

.one-more-thing {
  display: block;
  font-weight: 700;
}

.form-container {
  display: flex;
  justify-content: center;
  padding: 6px; /* Adjust padding as needed */
}

iframe {
  max-width: 100%;
  border: none;
}

/* Media query for smartphones */
@media (max-width: 600px) {
  .title-main {
    font-size: 48px; /* Bigger title for smartphones */
  }

  .title-sub {
    font-size: 18px; /* Smaller subtitle for smartphones */
  }

  .description {
    font-size: 18px; /* Smaller description for smartphones */
  }

  .info-text {
    font-size: 14px; /* Smaller info text for smartphones */
  }
}

/* Media query for laptop screens */
@media (min-width: 1024px) {
  .form-container {
    display: flex;
    justify-content: center;
    padding: 10px; /* Optional: Adjust padding for larger screens */
  }
}
</style>