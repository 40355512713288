<template>
  <div class="post-card" :class="{ expanded: isExpanded, 'mobile-expanded': isExpanded && isMobile }">
    <img class="post-image" :src="imageUrl" alt="Post Image" />
    <div class="post-content" :class="{ 'mobile-expanded-content': isExpanded && isMobile }">
      <h2 class="post-title">{{ title }}</h2>
      <p class="post-excerpt" v-if="!isExpanded">{{ excerpt }}</p>
      <div class="post-full" v-if="isExpanded">
        <p class="post-full-content">{{ content }}</p>
      </div>
      <button class="learn-more" v-if="!isExpanded" @click="toggleExpand">Learn More</button>
    </div>
    <button class="back-button" v-if="isExpanded" @click="toggleExpand">← Back</button>
  </div>
</template>

<script>
export default {
  name: 'BlogPostCard',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    excerpt: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false,
      isMobile: false
    };
  },
  computed: {
    imageUrl() {
      return require(`@/assets/${this.image}`);
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
    }
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  }
}
</script>

<style scoped>
.post-card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
  max-width: 900px;
  margin: 20px auto;
  backdrop-filter: blur(10px); /* Background blur effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft drop shadow */
}

.post-card.expanded {
  flex-direction: column;
  width: 80%; /* Reduced width to ensure proper centering */
  height: 60vh; /* Adjusted height for proper centering */
  position: flex;
  background: white;
  z-index: 1000;
  overflow: auto;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for expanded state */
}

.post-card.mobile-expanded {
  display: block; /* Stack elements vertically */
  width: 100%;
  height: auto;
  padding: 0;
}

.post-image {
  width: 300px;
  height: auto;
  border-radius: 8px; /* Rounded corners for images */
}

.post-content {
  padding: 20px;
  flex: 1;
  text-align: left; /* Left-to-right text alignment */
  position: relative; /* Added to contain absolute positioning */
  z-index: 1; /* Ensures content is above the image */
}

.post-content.mobile-expanded-content {
  background: none;
  color: black;
  position: relative;
}

.post-title {
  font-size: 24px;
  font-weight: bold;
}

.post-excerpt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative;
  font-weight: 300; /* Thin font weight for full content */

}


.post-full-content {
  font-weight: 300; /* Thin font weight for full content */
}

.learn-more {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 12px; /* Slightly rounded corners for button */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for button */
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: none;
  z-index: 1000;
  font-size: 18px;
  cursor: pointer;
  color: #000000;
}

.post-full {
  padding: 20px 0;
  font-weight: 300; /* Thin font weight for full content */
}

/* Media query for smartphones */
@media (max-width: 600px) {
  .post-card {
    display: block; /* Stack elements vertically */
  }

  .post-image {
    width: 100%; /* Full width image */
    height: auto;
  }

  .post-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; /* Full height of the image */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    color: white; /* Text color */
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    padding: 20px;
  }

  .post-card.mobile-expanded .post-content {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    display: block;
    background: none;
    color: black;
    padding: 20px;
  }

  .learn-more {
    position: relative; /* Remove absolute positioning */
    margin-top: 20px; /* Space above the button */
    align-self: center; /* Center button horizontally */
  }
}
</style>