<template>
  <div class="blog-page">
    <h1>Blog/News</h1>
<BlogPostCard
  image="antler-backing.png"
  title="Backed By Antler"
  excerpt="We are thrilled to announce that neocore has secured strategic backing from Antler, the globally renowned early-stage venture capital firm. This partnership underscores the trust and support we have received from Antler’s community..."
  content="
    We are thrilled to announce that neocore has secured strategic backing from Antler, the globally renowned early-stage venture capital firm. This partnership underscores the **trust and support** we have received from Antler’s community, especially their **partners and coaches**.

    Antler’s investment not only provides crucial funding but also connects us with their network of industry experts, mentors, and fellow innovators. This collaboration will enhance our capabilities, accelerate our growth, and help us deliver even more value to our users.

    We deeply appreciate the **trust** Antler has placed in us and are excited about this new chapter. Stay tuned for more updates as we embark on this exciting journey together.

    About Antler: Antler is a global early-stage venture capital firm that invests in the defining technology companies of tomorrow. They support exceptional founders from the earliest stages and help them grow and scale.
  "
/>
  </div>
</template>

<script>
import BlogPostCard from './BlogPostCard.vue';

export default {
  name: 'BlogPage',
  components: {
    BlogPostCard
  },
  mounted() {
    console.log('BlogPage mounted');
  }
}
</script>

<style scoped>
.blog-page {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
  font-weight: bold;
}
</style>