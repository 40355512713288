        <template>
  <div class="about-page">
    <h1>About Us</h1>
    <p>This is the about page.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  mounted() {
    console.log('AboutPage mounted');
  }
}
</script>

<style scoped>
.about-page {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background-color: lightpink;
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
  font-weight: bold;
}
</style>